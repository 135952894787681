<template>
  <div
    v-if="!isClosed && !next"
    class="onboard_first">
    <div class="onboard_first_head">
      <p class="heading_xl_h4">
        {{$t('how_it_works')}}
      </p>
    </div>
    <div class="onboard_first_body">
      <div>
        <p class="medium_m">{{$t('text_onboard')}}</p>
      </div>
      <div class="medium_bold_m onboard_first_body_bttn">
        <button
          @click="nextt"
          class="mr_16 bttn-primary brdr_r_8">
          {{$t('get_trained')}}
        </button>
        <button
          @click="closed"
          class="bttn-secondary brdr_r_8">
          {{$t('later')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirstWind',
  props: {
    next: [Boolean]
  },
  data () {
    return {
      isClosed: JSON.parse(localStorage.getItem('uData')).fst_vist
    }
  },
  methods: {
    closed: function () {
      this.isClosed = !this.isClosed
    },
    nextt: function () {
      this.$emit('next-wind', 1)
    }
  }
}
</script>
