<script setup>
import { defineEmits } from 'vue'
const emit = defineEmits(['set-data'])
const emitData = () => {
  emit('set-data')
}
</script>

<template>
  <div style="width: 100%">
    <p class="medium_m">
      {{$t('discleim')}}
    </p>
    <div>
      <button
        @click="emitData"
        class="medium_bold_s bttn bttn-primary brdr_r_8 settings_main_educate_add_bttn mt_12 mr_16">
        {{$t('ok')}}
      </button>
    </div>
  </div>
</template>
