import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import datas from './store/datas.js'
import VueI18n from 'vue-i18n'
import translations from '@/i18n/translations'
import vClickOutside from 'v-click-outside'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from '@/vuetify'
import VCalendar from 'v-calendar'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies'
import Skeleton from 'vue-loading-skeleton'
import country from 'country-list-js'

Vue.use(VueApexCharts)

Vue.prototype.$http = axios
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = token
}
Vue.use(Skeleton)
Vue.use(country)
Vue.use(VueCookies)
Vue.use(vClickOutside)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'ru',
  messages: translations
})
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
})
Vue.use(datas)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
