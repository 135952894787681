<template>
  <div
    v-if="!isClosed && !next"
    class="onboard_first">
    <div class="onboard_first_head">
      <p class="heading_xl_h4">
        {{$t('how_it_works')}}
      </p>
    </div>
    <div class="onboard_first_body">
      <div>
        <p class="medium_m">{{$t('fill_edu_and_certif')}}</p>
      </div>
      <div class="medium_bold_m onboard_first_body_bttn">
        <button
          @click="changeFirstVisit"
          class="mr_16 bttn-primary brdr_r_8">
          {{$t('ok')}}!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NinthWind',
  props: {
    next: [Boolean]
  },
  data () {
    return {
      uData: JSON.parse(localStorage.getItem('uData')),
      isClosed: false
    }
  },
  methods: {
    closed: function () {
      this.isClosed = !this.isClosed
    },
    changeFirstVisit () {
      this.$store.dispatch('changeFirstVisit')
        .then(() => {
          this.uData.fst_vist = true
          localStorage.setItem('uData', JSON.stringify(this.uData))
          this.$router.push({ name: 'settings', params: { ind: 1 } })
          this.closed()
        })
        .catch(err => {
          console.log(err.response.data.message)
        })
    }
  }
}
</script>
