<template>
  <div class="info_modal">
    <ul class="border_gray_2 brdr_r_12">
      <li v-for="(item, index) in faqChapterList"
        :key="item.id">
        <div
          class="flex faq_chapter"
          :class="[ dropdownField === index && isDropDown ? 'selected_chapter' : '']"
          @click="openDropdown(index, item.id)">
          <p class="medium_bold_l">{{item.title}}</p>
          <svg :class="[dropdownField === index && isDropDown ? 'rotate' : '']" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns= "http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4773 6.91336C21.1139 7.44341 21.2004 8.38922 20.6703 9.02588L14.7027 16.1937C13.3041 17.8737 10.7247 17.8747 9.32477 16.1957L3.33003 9.00604C2.79951 8.36977 2.88524 7.4239 3.52151 6.89338C4.15778 6.36286 5.10365 6.44859 5.63417 7.08486L11.6289 13.8937C11.8289 14.1336 12.1974 14.1335 12.3972 13.8935L18.3648 7.1064C18.8948 6.46974 19.8406 6.38331 20.4773 6.91336Z" fill="#004DE7"/>
          </svg>
        </div>
        <div v-if="selectedChapter === index && isDropDown" class="faq_question">
          <div v-for="elem in faqList"
            :key="elem.id">
            <p
              class="faq_question_title medium_bold_m"
              :class="[ openedAnswer === elem.id && isOpenAnswer ? 'selected_line' : '']"
              @click="openAnswer(elem.id)">
              {{elem.title}}
              <svg :class="[ openedAnswer === elem.id && isOpenAnswer ? '' : 'deg45']" width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.473134 2.75763C-0.157713 2.12679 -0.157713 1.10398 0.473134 0.473135C1.10398 -0.157712 2.12679 -0.157712 2.75763 0.473135L7 4.7155L11.2424 0.473135C11.8732 -0.157712 12.896 -0.157712 13.5269 0.473135C14.1577 1.10398 14.1577 2.12679 13.5269 2.75763L12.0476 4.23685L11.9987 4.28579L9.2845 7L12.7671 10.4826L12.7948 10.5103L13.5269 11.2424C14.1577 11.8732 14.1577 12.896 13.5269 13.5269C12.896 14.1577 11.8732 14.1577 11.2424 13.5269L7 9.2845L2.75763 13.5269C2.12679 14.1577 1.10398 14.1577 0.473136 13.5269C-0.157711 12.896 -0.157711 11.8732 0.473136 11.2424L1.20518 10.5103L1.23292 10.4826L4.7155 7L2.00129 4.28579C1.90334 4.18784 2.05024 4.33474 1.95235 4.23685L0.473134 2.75763Z" fill="#004DE7"/>
              </svg>
            </p>
            <p class="faq_question_text" v-if="isOpenAnswer && openedAnswer === elem.id">{{elem.text}}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'QuestionsPage',
  data () {
    return {
      dropdownField: null,
      isDropDown: false,
      faqChapterList: [],
      faqList: [],
      faqDetails: {},
      selectedChapter: null,
      openedAnswer: null,
      isOpenAnswer: false
    }
  },
  methods: {
    openDropdown: function (index, id) {
      if (this.selectedChapter === index) {
        this.isDropDown = false
        this.dropdownField = null
        this.selectedChapter = null
      } else {
        this.dropdownField = index
        this.selectedChapter = index
        this.isDropDown = true
        this.faqIdList(id)
      }
    },
    openAnswer: function (id) {
      if (this.openedAnswer === id) {
        this.isOpenAnswer = false
        this.openedAnswer = null
      } else {
        this.isOpenAnswer = true
        this.openedAnswer = id
      }
    },
    // axios function
    async faqListChapter () {
      await this.$store.dispatch('faqListChapter')
        .then((response) => {
          this.faqChapterList = response.data.data
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    async faqIdList (id) {
      this.faqList = []
      await this.$store.dispatch('faqIdList', id)
        .then((response) => {
          this.faqList = response.data.data
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    async faqDetail (id) {
      await this.$store.dispatch('faqDetail', id)
        .then((response) => {
          this.faqDetails = response.data.data
        })
        .catch(err => {
          console.log(err.response.data)
        })
    }
  },
  mounted () {
    this.faqListChapter()
  }
}
</script>
