<template>
  <div class="modal">
    <div class="brdr_r_12 bckg_white modal_block modal_max_width_720">
      <div class="modal_block_head">
        <div class="modal_block_head_title heading_2xl_h3">
          <slot
            name="head"></slot>
        </div>
        <div class="modal_block_head_item">
          <button @click="closeModal" v-if="!closed"  class="modal_block_head_item_button">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.473136 2.75763C-0.157712 2.12679 -0.157712 1.10398 0.473136 0.473135C1.10398 -0.157712 2.12679 -0.157712 2.75763 0.473135L7 4.7155L11.2424 0.473135C11.8732 -0.157712 12.896 -0.157712 13.5269 0.473135C14.1577 1.10398 14.1577 2.12679 13.5269 2.75763L12.0476 4.23685C11.9768 4.30768 12.0342 4.25035 12.0342 4.25035L11.9987 4.28579L9.2845 7L12.7671 10.4826L12.7948 10.5103L13.5269 11.2424C14.1577 11.8732 14.1577 12.896 13.5269 13.5269C12.896 14.1577 11.8732 14.1577 11.2424 13.5269L7 9.2845L2.75764 13.5269C2.12679 14.1577 1.10398 14.1577 0.473137 13.5269C-0.15771 12.896 -0.15771 11.8732 0.473137 11.2424L1.20518 10.5103L1.23292 10.4826L4.7155 7L2.00129 4.28579C1.95367 4.23817 1.96392 4.24842 1.97579 4.26028C1.98832 4.27282 2.00265 4.28715 1.95235 4.23685L0.473136 2.75763Z" fill="#004DE7"/>
            </svg>
            <p
              style="color: var(--blue);"
              class="medium_bold_m">{{$t('close')}}</p>
          </button>
        </div>
      </div>
      <div class="modal_block_body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent720',
  props: {
    closed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('close-modal')
    }
  }
}
</script>
