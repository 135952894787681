<template>
  <div>
    <ModalComponent720
      v-if="isOpen"
      :closed="true"
      @close-modal="closeModal()">
      <template v-slot:head>
        {{$t('educate')}}
      </template>
      <template v-slot:body>
        <div>
          <edu-component
            @check-edu="closeModal"/>
        </div>
      </template>
    </ModalComponent720>
  </div>
</template>

<script>
import ModalComponent720 from '@/components/modal/ModalComponent720'
import EduComponent from '../firstModal/EduComponent.vue'
export default {
  name: 'UserDataModal',
  props: {
    isOpen: {
      isOpen: Boolean
    }
  },
  components: { ModalComponent720, EduComponent },
  data () {
    return {
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('check-edu')
    }
  }
}
</script>
