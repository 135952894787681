<template>
  <div class="toast brdr_r_12 success_toast">
    <div class="toast_text">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.6339 8.48033C27.4732 9.19841 27.5714 10.4609 26.8534 11.3002L16.3438 23.5842C15.1171 25.0179 12.946 25.1486 11.5563 23.8723L5.31419 18.1398C4.50064 17.3926 4.44681 16.1274 5.19394 15.3139C5.94108 14.5003 7.20626 14.4465 8.01981 15.1936L13.7533 20.4591L23.814 8.69985C24.532 7.86054 25.7946 7.76226 26.6339 8.48033Z" fill="#0C947B"/>
      </svg>
      <p class="medium_bold_l">{{text}}</p>
    </div>
    <div class="toast_close medium_s" @click="close">
      {{$t('close')}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessToast',
  props: {
    text: String,
    show: Boolean
  },
  methods: {
    close: function () {
      this.$emit('close')
    }
  }
}
</script>
