<template>
  <div class="toast brdr_r_12 error_toast">
    <div class="toast_text">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29735 10.3433C6.45622 9.50222 6.45622 8.13848 7.29735 7.29735C8.13848 6.45622 9.50222 6.45622 10.3433 7.29735L15.9998 12.9538L21.6563 7.29735C22.4975 6.45622 23.8612 6.45622 24.7023 7.29735C25.5435 8.13848 25.5435 9.50222 24.7023 10.3433L22.73 12.3156C22.5995 12.4462 22.7954 12.2503 22.6648 12.3809L19.0458 15.9998L23.6893 20.6433L23.7263 20.6803L24.7023 21.6563C25.5435 22.4975 25.5435 23.8612 24.7023 24.7023C23.8612 25.5435 22.4975 25.5435 21.6563 24.7023L15.9998 19.0458L10.3434 24.7023C9.50222 25.5435 8.13848 25.5435 7.29735 24.7023C6.45622 23.8612 6.45622 22.4975 7.29735 21.6563L8.27341 20.6803L8.3104 20.6433L12.9538 15.9998L9.3349 12.3809L9.29415 12.3401C9.31041 12.3564 9.34625 12.3922 9.26964 12.3156L7.29735 10.3433Z" fill="#FF1E47"/>
      </svg>
      <p class="medium_bold_l">{{text}}</p>
    </div>
    <div class="toast_close medium_s" @click="close">
      {{$t('close')}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessToast',
  props: {
    text: String,
    show: Boolean
  },
  methods: {
    close: function () {
      this.$emit('close')
    }
  }
}
</script>
