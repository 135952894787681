<template>
  <div class="logotip">
    <img src="/img/logo.png" class="wider" alt="">
    <img src="/img/short.jpg" class="narrower" alt="">
  </div>
</template>

<script>
export default {
  name: 'LogotipPage',
  data () {
    return {
      logo: {}
    }
  },
  methods: {
    async getLogo () {
      await this.$store.dispatch('getLogo')
        .then((response) => {
          this.logo = response.data[0]
        })
        .catch(err => {
          this.error = err.response.data.message
        })
    }
  },
  mounted () {
    this.getLogo()
  }
}
</script>
