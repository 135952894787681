<template>
  <div
    v-if="!next"
    class="hint flex position_2_hint">
    <div class="hint_icon">
      <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.342 12.6365C15.5059 11.7878 16.4941 11.7878 16.658 12.6365L18.0552 19.8736C18.114 20.1783 18.3087 20.4162 18.5579 20.4881L24.4792 22.1958C25.1736 22.3961 25.1736 23.6039 24.4792 23.8042L18.558 25.5119C18.3087 25.5838 18.114 25.8217 18.0552 26.1264L16.658 33.3635C16.4941 34.2122 15.5059 34.2122 15.342 33.3635L13.9448 26.1264C13.886 25.8217 13.6913 25.5838 13.4421 25.5119L7.52079 23.8042C6.8264 23.6039 6.8264 22.3961 7.52079 22.1958L13.4421 20.4881C13.6913 20.4162 13.886 20.1783 13.9448 19.8736L15.342 12.6365Z" fill="#71A0FF"/>
        <path d="M4.63445 5.3472C4.72548 4.88427 5.27452 4.88427 5.36555 5.3472L6.14179 9.2947C6.17447 9.46088 6.2826 9.59064 6.42108 9.62985L9.71067 10.5613C10.0964 10.6706 10.0964 11.3294 9.71067 11.4387L6.42108 12.3702C6.2826 12.4094 6.17447 12.5391 6.14179 12.7053L5.36555 16.6528C5.27452 17.1157 4.72548 17.1157 4.63445 16.6528L3.85821 12.7053C3.82553 12.5391 3.7174 12.4094 3.57892 12.3702L0.28933 11.4387C-0.0964431 11.3294 -0.0964434 10.6706 0.28933 10.5613L3.57892 9.62985C3.7174 9.59064 3.82553 9.46088 3.85821 9.2947L4.63445 5.3472Z" fill="#71A0FF"/>
        <path d="M24.4882 0.462928C24.6157 -0.154309 25.3843 -0.154309 25.5118 0.462927L26.5985 5.72627C26.6443 5.94784 26.7956 6.12085 26.9895 6.17313L31.5949 7.41512C32.135 7.56077 32.135 8.43923 31.5949 8.58488L26.9895 9.82687C26.7956 9.87915 26.6443 10.0522 26.5985 10.2737L25.5118 15.5371C25.3843 16.1543 24.6157 16.1543 24.4882 15.5371L23.4015 10.2737C23.3557 10.0522 23.2044 9.87915 23.0105 9.82687L18.4051 8.58488C17.865 8.43923 17.865 7.56077 18.4051 7.41512L23.0105 6.17313C23.2044 6.12085 23.3557 5.94784 23.4015 5.72627L24.4882 0.462928Z" fill="#71A0FF"/>
      </svg>
    </div>
    <div class="medium_bold_s hint_text">
      <p>{{$t('third_hint')}}</p>
      <button
        @click="nextt"
        class="bttn-secondary brdr_r_8">
        {{$t('ok')}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondWind',
  props: {
    next: [Boolean]
  },
  data () {
    return {
    }
  },
  methods: {
    closed: function () {
    },
    nextt: function () {
      this.$emit('next-wind', 4)
    }
  }
}
</script>
