<template>
  <div class="navbar">
    <div class="menu medium_s" @click="closeMenu">
      <img src="/icons/Close=Filled.svg" alt="close">
      <p class="menu_close">{{$t('close')}}</p>
    </div>
    <div class="navbar_block flex bckg_white border_gray_2" style="flex-direction: column-reverse">
      <button
        @click="openOnboard(10)"
        :class="[selected === 8 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height">
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15 6H9C8.44772 6 8 6.44772 8 7V17C8 17.5523 8.44772 18 9 18H15C15.5523 18 16 17.5523 16 17V7C16 6.44772 15.5523 6 15 6ZM9 5C7.89543 5 7 5.89543 7 7V17C7 18.1046 7.89543 19 9 19H15C16.1046 19 17 18.1046 17 17V7C17 5.89543 16.1046 5 15 5H9Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20 9H19V18H20C20.5523 18 21 17.5523 21 17V10C21 9.44772 20.5523 9 20 9ZM19 8C18.4477 8 18 8.44771 18 9V18C18 18.5523 18.4477 19 19 19H20C21.1046 19 22 18.1046 22 17V10C22 8.89543 21.1046 8 20 8H19Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 9H5V18H4C3.44772 18 3 17.5523 3 17V10C3 9.44772 3.44772 9 4 9ZM5 8C5.55228 8 6 8.44771 6 9V18C6 18.5523 5.55228 19 5 19H4C2.89543 19 2 18.1046 2 17V10C2 8.89543 2.89543 8 4 8H5Z" fill="#CBD0EA"/>
          <path d="M14.1037 12.3669L10.9697 14.3256C10.7699 14.4505 10.5107 14.3068 10.5107 14.0712V10.1538C10.5107 9.91812 10.7699 9.77447 10.9697 9.89935L14.1037 11.8581C14.2917 11.9756 14.2917 12.2494 14.1037 12.3669Z" fill="#CBD0EA"/>
        </svg>
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 7C7 5.89543 7.89543 5 9 5H15C16.1046 5 17 5.89543 17 7V17C17 18.1046 16.1046 19 15 19H9C7.89543 19 7 18.1046 7 17V7Z" fill="#004DE7"/>
          <path d="M18 9C18 8.44771 18.4477 8 19 8H20C21.1046 8 22 8.89543 22 10V17C22 18.1046 21.1046 19 20 19H19C18.4477 19 18 18.5523 18 18V9Z" fill="#004DE7"/>
          <path d="M6 9C6 8.44771 5.55228 8 5 8H4C2.89543 8 2 8.89543 2 10V17C2 18.1046 2.89543 19 4 19H5C5.55228 19 6 18.5523 6 18V9Z" fill="#004DE7"/>
          <path d="M14.1037 12.3669L10.9697 14.3256C10.7699 14.4505 10.5107 14.3068 10.5107 14.0712V10.1538C10.5107 9.91812 10.7699 9.77447 10.9697 9.89935L14.1037 11.8581C14.2917 11.9756 14.2917 12.2494 14.1037 12.3669Z" fill="#CBD0EA"/>
        </svg>
        <p>{{$t('onboard')}}</p>
      </button>
      <button
        @click="openModal(8)"
        :class="[selected === 8 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" style="margin-top: 50px;">
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 11.2144C10.25 10.8001 10.5858 10.4644 11 10.4644H12H13C13.4142 10.4644 13.75 10.8001 13.75 11.2144C13.75 11.6286 13.4142 11.9644 13 11.9644H12.75V15.4644H14C14.4142 15.4644 14.75 15.8001 14.75 16.2144C14.75 16.6286 14.4142 16.9644 14 16.9644L12 16.9644H10C9.58579 16.9644 9.25 16.6286 9.25 16.2144C9.25 15.8001 9.58579 15.4644 10 15.4644H11.25V11.9644H11C10.5858 11.9644 10.25 11.6286 10.25 11.2144Z" fill="#CBD0EA"/>
          <circle cx="12" cy="8.13135" r="1.0957" fill="#CBD0EA"/>
        </svg>
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM10.25 11.2144C10.25 10.8001 10.5858 10.4644 11 10.4644H12H13C13.4142 10.4644 13.75 10.8001 13.75 11.2144C13.75 11.6286 13.4142 11.9644 13 11.9644H12.75V15.4644H14C14.4142 15.4644 14.75 15.8001 14.75 16.2144C14.75 16.6286 14.4142 16.9644 14 16.9644H12H10C9.58579 16.9644 9.25 16.6286 9.25 16.2144C9.25 15.8001 9.58579 15.4644 10 15.4644H11.25V11.9644H11C10.5858 11.9644 10.25 11.6286 10.25 11.2144ZM12 9.22705C12.6051 9.22705 13.0957 8.73649 13.0957 8.13135C13.0957 7.52621 12.6051 7.03564 12 7.03564C11.3949 7.03564 10.9043 7.52621 10.9043 8.13135C10.9043 8.73649 11.3949 9.22705 12 9.22705Z" fill="#004DE7"/>
        </svg>
        <p>{{$t('info')}}</p>
      </button>
      <!-- <button
        @click="openModal(7)"
        :class="[selected === 7 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" style="margin-top: 50px;">
        <img src="/icons/Rock=Outlined.svg" alt="info" class="fa">
        <img src="/icons/Onboard=Filled.svg" alt="info" class="fa">
        <p>{{$t('faq_menu')}}</p>
      </button> -->
      <!-- <router-link to="/"
        @click.native="selectLink(7)"
        :class="[selected === 7 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" >
        <img src="/icons/Rock=Outlined.svg" alt="info" class="fa">
        <img src="/icons/Onboard=Filled.svg" alt="info" class="fa">
        <p>{{$t('onboard')}}</p>
      </router-link> -->
      <router-link to="/catalog"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(6)"
        :class="[selected === 6 ? 'selected_link' : '']">
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="3.5" y="3.5" width="7" height="7" rx="1.5" stroke="#CBD0EA"/>
          <rect x="3.5" y="13.5" width="7" height="7" rx="1.5" stroke="#CBD0EA"/>
          <rect x="13.5" y="3.5" width="7" height="7" rx="1.5" stroke="#CBD0EA"/>
          <rect x="13.5" y="13.5" width="7" height="7" rx="1.5" stroke="#CBD0EA"/>
        </svg>
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="3" y="3" width="8" height="8" rx="2" fill="#004DE7"/>
          <rect x="3" y="13" width="8" height="8" rx="2" fill="#CBD0EA"/>
          <rect x="13" y="3" width="8" height="8" rx="2" fill="#004DE7"/>
          <rect x="13" y="13" width="8" height="8" rx="2" fill="#004DE7"/>
        </svg>
        <p>{{$t('catalog')}}</p>
      </router-link>
      <router-link to="/analytics"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(5)"
        :class="[selected === 5 ? 'selected_link' : '']">
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 13.5C19.2931 13.5 19.4716 13.7273 19.4476 13.9425C19.2972 15.2902 18.8259 16.5872 18.0675 17.7223C17.1335 19.1202 15.806 20.2096 14.2528 20.853C12.6996 21.4963 10.9906 21.6646 9.34174 21.3367C7.6929 21.0087 6.17834 20.1992 4.9896 19.0104C3.80085 17.8217 2.9913 16.3071 2.66333 14.6583C2.33535 13.0094 2.50368 11.3004 3.14703 9.74719C3.79037 8.19402 4.87984 6.8665 6.27766 5.93251C7.41275 5.17406 8.70979 4.70277 10.0575 4.55241C10.2727 4.5284 10.5 4.70686 10.5 5V12C10.5 12.8284 11.1716 13.5 12 13.5H19Z" stroke="#CBD0EA"/>
          <path d="M13.5 3C13.5 2.70686 13.7273 2.5284 13.9425 2.55241C14.7352 2.64085 15.513 2.8406 16.2528 3.14702C17.2841 3.57419 18.2211 4.20029 19.0104 4.98959C19.7997 5.77889 20.4258 6.71592 20.853 7.74719C21.1594 8.48696 21.3591 9.26482 21.4476 10.0575C21.4716 10.2727 21.2931 10.5 21 10.5L14 10.5C13.7239 10.5 13.5 10.2761 13.5 10L13.5 3Z" stroke="#CBD0EA"/>
        </svg>
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 13C19.5523 13 20.0057 13.4491 19.9445 13.998C19.7853 15.4249 19.2863 16.7983 18.4832 18.0001C17.4943 19.4802 16.0887 20.6337 14.4442 21.3149C12.7996 21.9961 10.99 22.1743 9.24419 21.8271C7.49836 21.4798 5.89471 20.6226 4.63604 19.364C3.37737 18.1053 2.5202 16.5016 2.17294 14.7558C1.82567 13.01 2.0039 11.2004 2.68509 9.55585C3.36628 7.91131 4.51983 6.50571 5.99987 5.51677C7.20174 4.71371 8.57508 4.2147 10.002 4.05549C10.5509 3.99425 11 4.44772 11 5V12C11 12.5523 11.4477 13 12 13H19Z" fill="#004DE7"/>
          <path d="M13 3C13 2.44771 13.4491 1.99426 13.998 2.0555C14.8372 2.14914 15.6609 2.36064 16.4442 2.68508C17.5361 3.13738 18.5282 3.80031 19.364 4.63604C20.1997 5.47177 20.8626 6.46392 21.3149 7.55585C21.6394 8.33913 21.8509 9.16275 21.9445 10.002C22.0057 10.5509 21.5523 11 21 11L14 11C13.4477 11 13 10.5523 13 10L13 3Z" fill="#CBD0EA"/>
        </svg>
        <p>{{$t('analytics')}}</p>
      </router-link>
      <router-link to="/users-list"
        v-if="uData.rle === 'admin' || uData.rle === 'admin_school'"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(4)"
        :class="[selected === 4 ? 'selected_link' : '']">
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 6.8645C10.974 6.8645 10.1423 7.69617 10.1423 8.72209C10.1423 9.74801 10.974 10.5797 11.9999 10.5797C13.0258 10.5797 13.8575 9.74801 13.8575 8.72209C13.8575 7.69617 13.0258 6.8645 11.9999 6.8645ZM9.14233 8.72209C9.14233 7.14389 10.4217 5.8645 11.9999 5.8645C13.5781 5.8645 14.8575 7.14389 14.8575 8.72209C14.8575 10.3003 13.5781 11.5797 11.9999 11.5797C10.4217 11.5797 9.14233 10.3003 9.14233 8.72209Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 12.5752C10.1566 12.5752 8.54274 13.5607 7.6575 15.036C7.35474 15.5406 7.44333 16.0234 7.77559 16.4177C8.12549 16.833 8.74703 17.1356 9.43973 17.1356H14.5605C15.2532 17.1356 15.8747 16.833 16.2246 16.4177C16.5569 16.0234 16.6455 15.5406 16.3427 15.036C15.4575 13.5607 13.8436 12.5752 12.0001 12.5752ZM6.80002 14.5215C7.85847 12.7575 9.79097 11.5752 12.0001 11.5752C14.2092 11.5752 16.1417 12.7575 17.2002 14.5215C17.7499 15.4376 17.5614 16.3832 16.9893 17.0621C16.4348 17.7201 15.5246 18.1356 14.5605 18.1356H9.43973C8.47558 18.1356 7.56539 17.7201 7.0109 17.0621C6.43877 16.3832 6.2503 15.4376 6.80002 14.5215Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73534 9.5896C5.03057 9.5896 4.45923 10.1609 4.45923 10.8657C4.45923 11.5705 5.03057 12.1418 5.73534 12.1418C6.44012 12.1418 7.01146 11.5705 7.01146 10.8657C7.01146 10.1609 6.44012 9.5896 5.73534 9.5896ZM3.45923 10.8657C3.45923 9.60865 4.47828 8.5896 5.73534 8.5896C6.99241 8.5896 8.01146 9.60865 8.01146 10.8657C8.01146 12.1228 6.99241 13.1418 5.73534 13.1418C4.47828 13.1418 3.45923 12.1228 3.45923 10.8657Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73534 13.9468C3.99927 13.9468 2.54217 15.1465 2.15017 16.7627C2.12828 16.8529 2.15126 16.9319 2.21575 17.0013C2.28608 17.077 2.40502 17.1357 2.54637 17.1357H7.38234C7.29725 16.9987 7.0366 16.8939 6.9811 16.749C6.79052 16.2514 6.44371 15.7885 6.53452 15.3367C6.62487 14.8872 6.75835 14.6802 6.9811 14.356C7.00083 14.3273 7.29153 14.1673 7.31228 14.1393C7.10156 14.09 6.44518 13.9468 5.73534 13.9468ZM1.17835 16.527C1.6766 14.4727 3.52716 12.9468 5.73534 12.9468C6.22097 12.9468 6.74995 13.0012 7.44549 13.3446L8.37621 13.8039L7.43686 14.2454C7.35968 14.2817 7.20449 14.4061 7.04253 14.6419C6.88787 14.867 6.75665 15.1517 6.69565 15.4552C6.63511 15.7564 6.64563 16.0643 6.75679 16.3546C6.86657 16.6412 7.087 16.9437 7.50131 17.2196L9.36365 18.1357H2.54637C1.76149 18.1357 0.951673 17.4615 1.17835 16.527Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2644 9.5896C18.9692 9.5896 19.5405 10.1609 19.5405 10.8657C19.5405 11.5705 18.9692 12.1418 18.2644 12.1418C17.5596 12.1418 16.9883 11.5705 16.9883 10.8657C16.9883 10.1609 17.5596 9.5896 18.2644 9.5896ZM20.5405 10.8657C20.5405 9.60865 19.5215 8.5896 18.2644 8.5896C17.0073 8.5896 15.9883 9.60865 15.9883 10.8657C15.9883 12.1228 17.0073 13.1418 18.2644 13.1418C19.5215 13.1418 20.5405 12.1228 20.5405 10.8657Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2644 13.9468C20.0005 13.9468 21.4576 15.1465 21.8496 16.7627C21.8715 16.8529 21.8485 16.9319 21.784 17.0013C21.7137 17.077 21.5947 17.1357 21.4534 17.1357H16.6793C16.7644 16.9987 16.9017 16.8571 16.9572 16.7122C17.1478 16.2147 17.1266 15.71 17.0358 15.2581C16.9454 14.8086 16.9884 14.3999 16.7656 14.0756C16.7459 14.0469 16.7629 14.1493 16.7422 14.1212C16.9572 13.9064 18.0872 13.9468 18.2644 13.9468ZM22.8214 16.527C22.3232 14.4727 20.4726 12.9468 18.2644 12.9468C17.7788 12.9468 17.2498 13.0012 16.5543 13.3446L15.6235 13.8039L16.5629 14.2454C16.6401 14.2817 16.7953 14.4061 16.9572 14.6419C17.1119 14.867 17.2431 15.1517 17.3041 15.4552C17.3646 15.7564 17.3541 16.0643 17.243 16.3546C17.1332 16.6412 16.9128 16.9437 16.4984 17.2196L15.1226 18.1357H21.4534C22.2383 18.1357 23.0481 17.4615 22.8214 16.527Z" fill="#CBD0EA"/>
        </svg>
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11.9999" cy="8.72209" r="2.35759" fill="#004DE7"/>
          <path d="M12.0001 12.0752C9.97374 12.0752 8.20057 13.1591 7.22873 14.7787C6.37625 16.1995 7.78284 17.6356 9.4397 17.6356H14.5605C16.2173 17.6356 17.6239 16.1995 16.7714 14.7787C15.7996 13.1591 14.0264 12.0752 12.0001 12.0752Z" fill="#004DE7"/>
          <circle cx="5.73534" cy="10.8657" r="1.77612" fill="#004DE7"/>
          <path d="M5.73535 13.4468C3.76323 13.4468 2.10941 14.8096 1.66427 16.6448C1.5341 17.1816 1.9941 17.6357 2.54639 17.6357H7.2242C5.26049 16.3281 6.4713 14.1468 7.2242 13.7929C6.61467 13.4921 6.16603 13.4468 5.73535 13.4468Z" fill="#CBD0EA"/>
          <circle cx="1.77612" cy="1.77612" r="1.77612" transform="matrix(-1 0 0 1 20.0405 9.0896)" fill="#004DE7"/>
          <path d="M18.2644 13.4468C20.2365 13.4468 21.8903 14.8096 22.3355 16.6448C22.4657 17.1816 22.0057 17.6357 21.4534 17.6357H16.7756C18.7393 16.3281 17.5285 14.1468 16.7756 13.7929C17.3851 13.4921 17.8337 13.4468 18.2644 13.4468Z" fill="#CBD0EA"/>
        </svg>
        <p>{{$t('user')}}</p>
      </router-link>
      <router-link to="/schedule"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(3)"
        :class="[selected === 3 ? 'selected_link' : '']">
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 8H22V5C22 3.89543 21.1046 3 20 3H4C2.89543 3 2 3.89543 2 5V8Z" stroke="#CBD0EA"/>
          <mask id="path-2-inside-1_167_7600" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 10H7.5V21H4C2.89543 21 2 20.1046 2 19V10ZM8.5 21V10H15.5V21H8.5ZM16.5 21H20C21.1046 21 22 20.1046 22 19V10H16.5V21Z"/>
          </mask>
          <path d="M7.5 10H8.5V9H7.5V10ZM2 10V9H1V10H2ZM7.5 21V22H8.5V21H7.5ZM8.5 10V9H7.5V10H8.5ZM8.5 21H7.5V22H8.5V21ZM15.5 10H16.5V9H15.5V10ZM15.5 21V22H16.5V21H15.5ZM16.5 21H15.5V22H16.5V21ZM22 10H23V9H22V10ZM16.5 10V9H15.5V10H16.5ZM7.5 9H2V11H7.5V9ZM8.5 21V10H6.5V21H8.5ZM4 22H7.5V20H4V22ZM1 19C1 20.6569 2.34315 22 4 22V20C3.44772 20 3 19.5523 3 19H1ZM1 10V19H3V10H1ZM7.5 10V21H9.5V10H7.5ZM15.5 9H8.5V11H15.5V9ZM16.5 21V10H14.5V21H16.5ZM8.5 22H15.5V20H8.5V22ZM16.5 22H20V20H16.5V22ZM20 22C21.6569 22 23 20.6569 23 19H21C21 19.5523 20.5523 20 20 20V22ZM23 19V10H21V19H23ZM22 9H16.5V11H22V9ZM15.5 10V21H17.5V10H15.5Z" fill="#CBD0EA" mask="url(#path-2-inside-1_167_7600)"/>
        </svg>
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 8H22V5C22 3.89543 21.1046 3 20 3H4C2.89543 3 2 3.89543 2 5V8Z" fill="#004DE7"/>
          <path d="M2 10H22V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V10Z" fill="#004DE7"/>
          <path d="M8 10V21" stroke="#CBD0EA" stroke-width="2"/>
          <path d="M16 10V21" stroke="#CBD0EA" stroke-width="2"/>
        </svg>
        <p>{{$t('schedule')}}</p>
      </router-link>
      <div class="inner" v-if="uData.rle === 'admin_school'">
        <router-link to="/"
          @click.native="selectLink(2)"
          :class="[selected === 2 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="school_course" class="circle_icon">
          <p>{{$t('school_course')}}</p>
        </router-link>
        <router-link to="/lessons"
          @click.native="selectLink(11)"
          :class="[selected === 11 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="school_course" class="circle_icon">
          <p>{{$t('lesson_section')}}</p>
        </router-link>
        <router-link to="/my-course"
          @click.native="selectLink(1)"
          :class="[selected === 1 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="school_course" class="circle_icon">
          <p>{{$t('my_course')}}</p>
        </router-link>
      </div>
      <div
        v-if="uData.rle === 'admin_school'"
        class="navbar_block_list flex outter main_list_height cocourse_reverseurse"
        :class="[(selected === 2 || selected === 1 || selected === 11) ? 'selected_link' : '']">
        <svg class="fa c1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.9994 15.2437V20.5C20.9994 21.6046 20.104 22.5 18.9994 22.5H5.31177C4.2072 22.5 3.31177 21.6046 3.31177 20.5V19.7484C3.31177 18.6438 4.2072 17.7484 5.31177 17.7484H19.004C19.4671 17.7484 19.9159 17.5876 20.2738 17.2935L20.5988 17.0264L20.9994 16.2451V15.2437Z" stroke="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 4C2.5 2.61929 3.61929 1.5 5 1.5H19C20.3807 1.5 21.5 2.61929 21.5 4V15.7429C21.5 17.1236 20.3807 18.2429 19 18.2429H5.31732C4.73056 18.2429 4.19768 18.585 3.95344 19.1185L3.64555 20.7911C3.37733 21.377 2.5 21.1857 2.5 20.5413V4ZM5 2.5C4.17157 2.5 3.5 3.17157 3.5 4V18.026C3.96441 17.5345 4.6179 17.2429 5.31732 17.2429H19C19.8284 17.2429 20.5 16.5713 20.5 15.7429V4C20.5 3.17157 19.8284 2.5 19 2.5H5Z" fill="#CBD0EA"/>
          <path d="M7 8.5C7 7.67157 7.67157 7 8.5 7H15.5C16.3284 7 17 7.67157 17 8.5V8.5C17 9.32843 16.3284 10 15.5 10H8.5C7.67157 10 7 9.32843 7 8.5V8.5Z" stroke="#CBD0EA"/>
        </svg>
        <svg class="fa c2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.1146 17.8462H2.52295V20.5C2.52295 21.6046 3.41838 22.5 4.52295 22.5H19.4999C20.6044 22.5 21.4999 21.6046 21.4999 20.5V15.0106L20.1146 17.8462Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1.5C3.61929 1.5 2.5 2.61929 2.5 4V20.5413C2.5 21.1857 3.37733 21.377 3.64555 20.7911L3.95344 19.1185C4.19768 18.585 4.73056 18.2429 5.31732 18.2429H19C20.3807 18.2429 21.5 17.1236 21.5 15.7429V4C21.5 2.61929 20.3807 1.5 19 1.5H5Z" fill="#004DE7"/>
          <path d="M7 8.5C7 7.67157 7.67157 7 8.5 7H15.5C16.3284 7 17 7.67157 17 8.5C17 9.32843 16.3284 10 15.5 10H8.5C7.67157 10 7 9.32843 7 8.5Z" fill="#CBD0EA"/>
        </svg>
        <p>{{$t('course')}}</p>
      </div>
      <router-link to="/my-course"
        v-else
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(1)"
        :class="[selected === 1 ? 'selected_link' : '']">
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.9994 15.2437V20.5C20.9994 21.6046 20.104 22.5 18.9994 22.5H5.31177C4.2072 22.5 3.31177 21.6046 3.31177 20.5V19.7484C3.31177 18.6438 4.2072 17.7484 5.31177 17.7484H19.004C19.4671 17.7484 19.9159 17.5876 20.2738 17.2935L20.5988 17.0264L20.9994 16.2451V15.2437Z" stroke="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 4C2.5 2.61929 3.61929 1.5 5 1.5H19C20.3807 1.5 21.5 2.61929 21.5 4V15.7429C21.5 17.1236 20.3807 18.2429 19 18.2429H5.31732C4.73056 18.2429 4.19768 18.585 3.95344 19.1185L3.64555 20.7911C3.37733 21.377 2.5 21.1857 2.5 20.5413V4ZM5 2.5C4.17157 2.5 3.5 3.17157 3.5 4V18.026C3.96441 17.5345 4.6179 17.2429 5.31732 17.2429H19C19.8284 17.2429 20.5 16.5713 20.5 15.7429V4C20.5 3.17157 19.8284 2.5 19 2.5H5Z" fill="#CBD0EA"/>
          <path d="M7 8.5C7 7.67157 7.67157 7 8.5 7H15.5C16.3284 7 17 7.67157 17 8.5V8.5C17 9.32843 16.3284 10 15.5 10H8.5C7.67157 10 7 9.32843 7 8.5V8.5Z" stroke="#CBD0EA"/>
        </svg>
        <svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.1146 17.8462H2.52295V20.5C2.52295 21.6046 3.41838 22.5 4.52295 22.5H19.4999C20.6044 22.5 21.4999 21.6046 21.4999 20.5V15.0106L20.1146 17.8462Z" fill="#CBD0EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1.5C3.61929 1.5 2.5 2.61929 2.5 4V20.5413C2.5 21.1857 3.37733 21.377 3.64555 20.7911L3.95344 19.1185C4.19768 18.585 4.73056 18.2429 5.31732 18.2429H19C20.3807 18.2429 21.5 17.1236 21.5 15.7429V4C21.5 2.61929 20.3807 1.5 19 1.5H5Z" fill="#004DE7"/>
          <path d="M7 8.5C7 7.67157 7.67157 7 8.5 7H15.5C16.3284 7 17 7.67157 17 8.5C17 9.32843 16.3284 10 15.5 10H8.5C7.67157 10 7 9.32843 7 8.5Z" fill="#CBD0EA"/>
        </svg>
        <p>{{$t('my_course')}}</p>
      </router-link>
    </div>
    <div>
      <ModalComponent
        v-if="isModalOpen"
        @close-modal="closeModal()">
        <template v-slot:head>
          {{modalTitle}}
        </template>
        <template v-slot:body>
          <InfoPageM v-if="selected === 8"/>
          <questions-page v-if="selected === 7"/>
        </template>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
import ModalComponent from '@/components/modal/ModalComponent'
import InfoPageM from '../views/info/InfoPageM.vue'
import QuestionsPage from '../views/questions/QuestionsPage.vue'

export default {
  name: 'NavbarPage',
  props: {
    isOpen: Boolean,
    isProfile: Boolean
  },
  components: {
    ModalComponent,
    InfoPageM,
    QuestionsPage
  },
  data () {
    return {
      selected: Number(localStorage.getItem('nb')),
      isModalOpen: false,
      prevSelected: '',
      uData: JSON.parse(localStorage.getItem('uData')),
      modalTitle: ''
    }
  },
  watch: {
    isProfile: function () {
      this.selectProfile()
    }
  },
  methods: {
    checkAccess: function () {
      this.$emit('access-check')
    },
    selectLink: function (ind) {
      if (this.$cookies.isKey('abtCourseTabsSelect')) { this.$cookies.remove('abtCourseTabsSelect') }
      localStorage.setItem('nb', ind)
      this.selected = Number(localStorage.getItem('nb'))
      this.closeMenu()
      this.checkAccess()
    },
    selectProfile: function () {
      this.selected = Number(localStorage.getItem('nb'))
    },
    closeMenu: function () {
      this.$emit('hide-or-show', false)
    },
    // modal
    closeModal: function () {
      this.isModalOpen = false
      this.selected = Number(localStorage.getItem('nb'))
      this.$emit('close-modal')
    },
    openModal: function (numb) {
      this.isModalOpen = true
      this.selected = numb
      this.modalTitle = numb === 8 ? `${this.$t('info')}` : `${this.$t('faq')}`
    },
    openOnboard: function (numb) {
      this.selected = numb
      this.$emit('open-onboard')
    }
  }
}
</script>
