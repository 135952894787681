<template>
  <div class="toast brdr_r_12 warn_toast">
    <div class="toast_text">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM14 10C14 8.89543 14.8954 8 16 8C17.1046 8 18 8.89543 18 10V16.6667C18 17.7712 17.1046 18.6667 16 18.6667C14.8954 18.6667 14 17.7712 14 16.6667V10ZM16 24.6667C17.1046 24.6667 18 23.7712 18 22.6667C18 21.5621 17.1046 20.6667 16 20.6667C14.8954 20.6667 14 21.5621 14 22.6667C14 23.7712 14.8954 24.6667 16 24.6667Z" fill="#DE5D00"/>
      </svg>
      <p class="medium_bold_l">{{text}}</p>
    </div>
    <div class="toast_close medium_s" @click="close">
      {{$t('close')}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarningToast',
  props: {
    text: String,
    show: Boolean
  },
  methods: {
    close: function () {
      this.$emit('close')
    }
  },
  mounted () {
    setTimeout(() => {
      this.close()
    }, 2000)
  }
}
</script>
