<template>
  <div
    v-if="!next && !isClosed"
    class="onboard_first">
    <div class="onboard_first_head">
      <p class="heading_xl_h4">
        {{$t('how_it_works')}}
      </p>
    </div>
    <div class="onboard_first_sections">
      <div class="onboard_first_range">
        <p class="medium_m" v-if="percent === 100">{{$t('onboard_done')}}</p>
        <p class="medium_m" v-else>{{$t('select_learn_select')}}</p>
        <v-app class="onboard_first_range_item">
          <v-progress-linear
            v-model="linePercent"
            rounded
            class="v-progress-linear"/>
          <div class="flex jc_sp_b onboard_first_range_item_percent medium_s">
            <p>{{linePercent}}%</p>
            <p>100%</p>
          </div>
        </v-app>
      </div>
      <div class="onboard_first_section">
        <div class="onboard_first_section_one jc_sp_b flex" @click="nextt('first')">
          <p class="medium_bold_m">
            {{$t('one_section')}}
          </p>
          <svg v-if="firstSection === ''" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84134 3.53119C7.37139 2.89453 8.3172 2.8081 8.95386 3.33815L16.1217 9.30572C17.8017 10.7044 17.8027 13.2838 16.1237 14.6837L8.93402 20.6784C8.29775 21.209 7.35188 21.1232 6.82136 20.487C6.29084 19.8507 6.37656 18.9048 7.01283 18.3743L13.8217 12.3796C14.0616 12.1796 14.0614 11.8111 13.8214 11.6113L7.03438 5.64371C6.39772 5.11366 6.31129 4.16785 6.84134 3.53119Z" fill="#004DE7"/>
          </svg>
          <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9752 6.36025C20.6046 6.89881 20.6783 7.84569 20.1398 8.47517L12.2576 17.6881C11.3376 18.7634 9.70927 18.8614 8.66695 17.9042L3.9854 13.6048C3.37524 13.0445 3.33486 12.0956 3.89521 11.4854C4.45557 10.8753 5.40445 10.8349 6.01462 11.3952L10.3147 15.3443L17.8602 6.52488C18.3988 5.8954 19.3457 5.82169 19.9752 6.36025Z" fill="#0C947B"/>
          </svg>
        </div>
        <div class="onboard_first_section_one jc_sp_b flex" @click="nextt('third')">
          <p class="medium_bold_m p_info_width">
            {{$t('three_section')}}
          </p>
          <svg v-if="thirdSection === ''" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84134 3.53119C7.37139 2.89453 8.3172 2.8081 8.95386 3.33815L16.1217 9.30572C17.8017 10.7044 17.8027 13.2838 16.1237 14.6837L8.93402 20.6784C8.29775 21.209 7.35188 21.1232 6.82136 20.487C6.29084 19.8507 6.37656 18.9048 7.01283 18.3743L13.8217 12.3796C14.0616 12.1796 14.0614 11.8111 13.8214 11.6113L7.03438 5.64371C6.39772 5.11366 6.31129 4.16785 6.84134 3.53119Z" fill="#004DE7"/>
          </svg>
          <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9752 6.36025C20.6046 6.89881 20.6783 7.84569 20.1398 8.47517L12.2576 17.6881C11.3376 18.7634 9.70927 18.8614 8.66695 17.9042L3.9854 13.6048C3.37524 13.0445 3.33486 12.0956 3.89521 11.4854C4.45557 10.8753 5.40445 10.8349 6.01462 11.3952L10.3147 15.3443L17.8602 6.52488C18.3988 5.8954 19.3457 5.82169 19.9752 6.36025Z" fill="#0C947B"/>
          </svg>
        </div>
        <div class="onboard_first_section_two jc_sp_b flex" @click="nextt('second')">
          <p class="medium_bold_m">
            {{$t('two_section')}}
          </p>
          <svg v-if="secondSection === ''" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84134 3.53119C7.37139 2.89453 8.3172 2.8081 8.95386 3.33815L16.1217 9.30572C17.8017 10.7044 17.8027 13.2838 16.1237 14.6837L8.93402 20.6784C8.29775 21.209 7.35188 21.1232 6.82136 20.487C6.29084 19.8507 6.37656 18.9048 7.01283 18.3743L13.8217 12.3796C14.0616 12.1796 14.0614 11.8111 13.8214 11.6113L7.03438 5.64371C6.39772 5.11366 6.31129 4.16785 6.84134 3.53119Z" fill="#004DE7"/>
          </svg>
          <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9752 6.36025C20.6046 6.89881 20.6783 7.84569 20.1398 8.47517L12.2576 17.6881C11.3376 18.7634 9.70927 18.8614 8.66695 17.9042L3.9854 13.6048C3.37524 13.0445 3.33486 12.0956 3.89521 11.4854C4.45557 10.8753 5.40445 10.8349 6.01462 11.3952L10.3147 15.3443L17.8602 6.52488C18.3988 5.8954 19.3457 5.82169 19.9752 6.36025Z" fill="#0C947B"/>
          </svg>
        </div>
      </div>
      <div class="medium_bold_m onboard_first_sections_bttn">
        <button
          v-if="percent === 100"
          @click="ninthW"
          class="bttn-secondary brdr_r_8">
          {{$t('next')}}
        </button>
        <button
          v-else
          @click="closed"
          class="bttn-secondary brdr_r_8">
          {{$t('later')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondWind',
  props: {
    next: [Boolean],
    percent: [Number],
    firstSection: [String],
    secondSection: [String],
    thirdSection: [String]
  },
  data () {
    return {
      uData: JSON.parse(localStorage.getItem('uData')),
      linePercent: this.percent,
      isClosed: false
    }
  },
  watch: {
    percent: function () {
      this.set()
    }
  },
  methods: {
    set: function () {
      this.linePercent = this.percent
    },
    closed: function () {
      this.isClosed = !this.isClosed
    },
    nextt: function (itm) {
      const data = [itm, 2]
      this.$emit('next-wind', data)
    },
    ninthW: function () {
      const data = ['first', 9]
      this.$emit('next-wind', data)
    }
  }
}
</script>

<style lang="scss">
.onboard_first_range > .v-application > .v-application--wrap {
  min-height: unset !important;
}
.onboard_first_range .v-application p {
  margin-bottom: 0px;
}
</style>
