import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translations from '@/i18n/translations'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'ru',
  messages: translations
})
