<template>
  <div class="load">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {
  name: 'PreLoader'
}
</script>
