<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
  methods: {
    getFirstLang: function () {
      if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
        localStorage.setItem('lang', 0)
      }
    }
  },
  mounted () {
    this.getFirstLang()
  }
}
</script>
