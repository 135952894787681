<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from './layouts/EmptyLayout'

export default {
  components: { MainLayout, EmptyLayout },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Excourse'
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'Empty') + 'Layout'
    }
  }
}
</script>

<style lang="scss">
@import "assets/css/index.scss";
</style>
