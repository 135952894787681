<template>
  <div class="info_modal">
    <ul v-if="selectedDoc === ''" class="medium_bold_l border_gray_2 brdr_r_12">
      <li v-for="(item, index) in list"
        :key="item"
        class="flex jc_sp_b">
        <p class="m_auto_bt_12">{{item}}</p>
        <div>
          <a href="https://wa.me/77004881638" target="_blank" v-if="index === 6" class="flex bttn-secondary brdr_r_8">
            <span>{{$t('open')}}</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.39553 5.00526C8.93529 5.00527 8.5622 5.37836 8.5622 5.8386C8.5622 6.29884 8.93529 6.67193 9.39553 6.67193L12.0977 6.67193L5.27074 13.4989C4.9453 13.8244 4.9453 14.352 5.27074 14.6774C5.59618 15.0029 6.12382 15.0029 6.44925 14.6774L13.2762 7.85044L13.2762 10.5526C13.2762 11.0129 13.6493 11.386 14.1096 11.386C14.5698 11.386 14.9429 11.0129 14.9429 10.5526L14.9429 6.0886C14.9429 5.49029 14.4579 5.00527 13.8596 5.00527L9.39553 5.00526Z" fill="#004DE7"/>
            </svg>
          </a>
          <button v-else @click="openDoc(index)" class="flex bttn-secondary brdr_r_8">
            <span>{{$t('open')}}</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.39553 5.00526C8.93529 5.00527 8.5622 5.37836 8.5622 5.8386C8.5622 6.29884 8.93529 6.67193 9.39553 6.67193L12.0977 6.67193L5.27074 13.4989C4.9453 13.8244 4.9453 14.352 5.27074 14.6774C5.59618 15.0029 6.12382 15.0029 6.44925 14.6774L13.2762 7.85044L13.2762 10.5526C13.2762 11.0129 13.6493 11.386 14.1096 11.386C14.5698 11.386 14.9429 11.0129 14.9429 10.5526L14.9429 6.0886C14.9429 5.49029 14.4579 5.00527 13.8596 5.00527L9.39553 5.00526Z" fill="#004DE7"/>
            </svg>
          </button>
        </div>
      </li>
    </ul>
    <div class="info_media">
      <div v-if="selectedDoc === 0" class="info_media_right">
        <iframe title="" src="/pdf/Патент на Excourse.pdf#toolbar=0" width="100%" height="500px" />
      </div>
      <div v-if="selectedDoc === 1">
        <iframe title="" src="/pdf/Обработка персональных данных.pdf#toolbar=0" width="100%" height="500px" />
      </div>
      <div v-if="selectedDoc === 2">
        <iframe title="" src="/pdf/Политика конфиденциальности.pdf#toolbar=0" width="100%" height="500px" />
      </div>
      <div v-if="selectedDoc === 3">
        <iframe title="" src="/pdf/Оферта по оказанию услуг.pdf#toolbar=0" width="100%" height="500px" />
      </div>
      <div v-if="selectedDoc === 4">
        <iframe title="" src="/pdf/Безопасностьонлайнплатежей.pdf#toolbar=0" width="100%" height="500px" />
      </div>
      <div v-if="selectedDoc === 5">
        <iframe title="" src="/pdf/Реквизиты.pdf#toolbar=0" width="100%" height="500px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoPageM',
  data () {
    return {
      selectedDoc: '',
      list: [`${this.$t('all_right_reserved')}`, `${this.$t('personal_data')}`, `${this.$t('confidentiality')}`, `${this.$t('offer')}`, `${this.$t('online_payments_security')}`, `${this.$t('requisites')}`, `${this.$t('for_questions')}:  +7 700 488 16 38`]
    }
  },
  methods: {
    openDoc: function (ind) {
      this.selectedDoc = ind
    }
  }
}
</script>
